@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

ion-content{
  --background: linear-gradient(#E1F5FE 0%, #FFF 40%, #FFF 60%, #F1F8E9 100%);
}

.brand{
  font-family: 'Pacifico', cursive;
}

.pacifico{
  font-family: 'Pacifico', cursive;
}

p.lead{
  font-size: 21px;
  font-weight: 400;
  margin-bottom: .5rem;
}

.doc{
  h3{
    font-size: 21px;
    font-weight: 500;
    margin: 1rem 0 .5rem 0;
  }
  h4{
    font-size: 16px;
    font-weight: 500;
    margin: 1rem 0 .5rem 0;
  }
  p{
    margin-bottom: .5rem;
  }
}

markdown{
  h1{
    font-weight: 900;
  }
  h2{
    font-weight: 700;
  }
  h3{
    font-weight: 500;
  }
  blockquote{
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    color: var(--ion-color-medium);
    font-style: italic;
  }
  hr{
    background: var(--ion-color-light);
  }
  a{
    text-decoration: none;
  }
}

.form{
  ion-label{
    font-weight: 700;
  }
  ion-radio-group{
    ion-label{
      font-weight: 400;
    }
  }
}
